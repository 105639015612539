import { useState, useEffect } from "react";
import { axiosInstance } from "../libries";

// components
import Layout from "../containers/Layout";
import SimilarProduct from "../components/SimilarProduct";
import { SimilarProductLoader } from "../components/SimilarProduct";
import LimitedOffer from "../components/LimitedOffer";
import Breadcrumb from "../components/Breadcrumb";
import AvailableCart from "../components/AvailableCart";
import EmptyCart from "../components/EmptyCart";
import { useSelector, useDispatch } from "react-redux";

import { addCartItem, clearCarts } from "../store/slices/cartSlice";

const Cart = () => {
  const dispatch = useDispatch();
  const { carts } = useSelector((state) => state.cart);
  const [cartBanners, setCartBanners] = useState(null);
  const usertoken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = useState(true);

  const [gadgets, setGadgets] = useState(null);

  // banners for cart page
  useEffect(() => {
    axiosInstance()
      .get(`/banner/cart-page`)
      .then((resp) => {
        const res = resp.data.data.banners;
        setCartBanners(res);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    axiosInstance()
      .get(`/likeable-products`)
      .then((resp) => {
        setGadgets(resp.data.data.likeable_products);
      })
      .catch((e) => {});
  }, []);

  // fetching carts here
  useEffect(() => {
    // setLoading(true);

    if (usertoken)
      axiosInstance(usertoken)
        .get("/carts")
        .then((resp) => {
          const allCarts = resp.data.data.cart;
          dispatch(clearCarts());
          if (allCarts) {
            allCarts.map((cart) => {
              let allVariation = [];

              if (cart.variation !== null) {
                cart.variation.forEach((cartVar) => {
                  allVariation.push({
                    amount: cartVar.amount,
                    id: cartVar.combo_id,
                    quantity: cartVar.quantity,
                    total: Number(cartVar.amount * cartVar.quantity),
                    stock_count: cartVar.in_stock,
                    variations: cartVar.variations,
                  });
                });
              }
              return dispatch(
                addCartItem({
                  id: cart.product.id,
                  p_slug: cart.product.p_slug,
                  name: cart.product.name,
                  color: cart.product.color,
                  quantity: cart.quantity,
                  src: cart.images,
                  brand: "",
                  price: Number(cart.product.amount),
                  description: cart.product.description,
                  total: Number(cart.product.amount) * cart.quantity,
                  variation_info: cart.product.is_fixed ? null : allVariation,
                  stock_count:
                    cart.product.is_fixed === 1
                      ? cart.product.stock_count
                      : cart.product.in_stock,
                  weight: cart.product.weight,
                })
              );
            });
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
  }, [dispatch, usertoken]);

  return (
    <Layout>
      <Breadcrumb name="Product detail >" />
      <div className="container">
        {usertoken && loading && (
          <div
            className="loading__spin__address"
            style={{ marginBottom: "5%", marginTop: "3%" }}
          >
            <span className="loader"></span>
          </div>
        )}
        {usertoken && !loading && carts?.length ? <AvailableCart /> : null}
        {usertoken && !loading && !carts?.length ? <EmptyCart /> : null}
        {!usertoken && !carts?.length ? <EmptyCart /> : null}
        {!usertoken && carts?.length ? <AvailableCart /> : null}

        {gadgets ? (
          <SimilarProduct title="Gadgets you may also like" data={gadgets} />
        ) : (
          <div className="similar">
            <h3 className="similar__name">Similar Products</h3>
            <div className="similar__product">
              <div className="similar__pictures">
                {new Array(7).fill(null).map((_, i) => (
                  <SimilarProductLoader key={i} />
                ))}
              </div>
            </div>
          </div>
        )}
        <LimitedOffer cartBanners={cartBanners} />
      </div>
    </Layout>
  );
};

export default Cart;

import { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";

import { baseUrl } from "../libries";

import { useSelector } from "react-redux";

// images
import logo from "../assets/new-imgs/shopnig-logoo.svg";
import authphoto from "../assets/new-imgs/auth-banner.svg";
import { isNumber } from "../utils/func";
import useAlert from "../hooks/useAlert";
import authbg from "../assets/new-imgs/auth-dark.svg";
import logodark from "../assets/new-imgs/shopnig-darklogo.svg";

import whatsapp from "../assets/new-imgs/whatsapp.png";
import sms from "../assets/new-imgs/sms.png";
import validator from "validator";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const submitBtnRef = useRef();
  const uiMode = useSelector((state) => state.app.uiMode);

  // state to get login inputs
  const [userLoginDetails, setUserLoginDetails] = useState({
    phone: "",
    email: "",
  });

  // Type
  const [type,] = useState("email");

  // sms type
  const [smsType, setSmsType] = useState("whatsapp");

  const [message, setMessage, clearMessage] = useAlert();

  const handleLoginInputs = (e) => {
    const { name, value } = e.target;
    setUserLoginDetails({
      ...userLoginDetails,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!activateLogin) return setMessage("warning", "Fill in all fields");

    if (type === "email" && !validator.isEmail(userLoginDetails.email))
      return setMessage("warning", "Enter a valid email address");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Processing`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    axios({
      url: `${baseUrl}/forgot/password`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        mode: type === "phone" ? "phone_number" : type,
        [type === "phone" ? "phone_number" : type]: `${
          type === "phone" ? "+234" : ""
        }${userLoginDetails[type]}`,
        option: smsType,
      }),
    })
      .then((response) => {
        if (response.data.status) {
          navigate("/reset-password");
        } else {
          setMessage("warning", response.data.message);
          throw new Error("");
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        submitBtnRef.current.removeAttribute("disabled", "disabled");
        submitBtnRef.current.innerHTML = "Send password reset code";
      });
  };

  useEffect(() => {
    clearMessage();
  }, [
    type,
    userLoginDetails.email,
    userLoginDetails.phone,
    smsType,
    clearMessage,
  ]);

  const activateLogin = userLoginDetails[type];

  return (
    <>
      <div className="auth__container">
        <div className="auth__photo">
          <img src={uiMode === "light" ? authphoto : authbg} alt="login" />
        </div>
        <form
          onSubmit={handleSubmit}
          className="auth__main auth__main--1"
          style={{
            // minHeight: "80vh",
            paddingBottom: "20rem",
          }}
        >
          <div className="auth__img-block">
            <a href="/">
              <img
                src={uiMode === "light" ? logo : logodark}
                alt=""
                className={["auth__img", "cursor-pointer"].join(" ")}
              />
            </a>
          </div>
          <div className="auth__card">
            <div className="auth__card-main">
              <div className="auth__header" style={{ marginBottom: "2.4rem" }}>
                <h3 className="auth__heading">Forgot your password</h3>
                <p className="auth__subheading">
                  Don't worry chief, just select the mode you registered with
                  and we will send a code to you to reset your password.
                </p>
              </div>
              <div className="auth__btns">
                {/* <button
                  type="button"
                  autoComplete="off"
                  className={type === "email" ? "active" : ""}
                  onClick={() => setType("email")}
                >
                  Email
                </button> */}
                {/* <button
                  type="button"
                  className={type === "phone" ? "active" : ""}
                  onClick={() => setType("phone")}
                >
                  Phone
                </button> */}
              </div>
              <div className="auth__form">
                {type === "email" ? (
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      name="email"
                      value={userLoginDetails.email}
                      type="email"
                      placeholder="Enter email address"
                      autoComplete="off"
                      className="form-input form-input--1"
                      onChange={handleLoginInputs}
                    />
                  </div>
                ) : (
                  <div className="form-group">
                    <label className="form-label">Phone Number</label>
                    <div className="form-input-group form-input-group--1">
                      <button type="button">+234</button>
                      <input
                        name="phone"
                        value={userLoginDetails.phone}
                        type="text"
                        placeholder="Enter your phone number"
                        autoComplete="off"
                        onChange={(e) =>
                          e.target.value
                            ? isNumber(e.target.value)
                              ? setUserLoginDetails((uD) => ({
                                  ...uD,
                                  phone: +e.target.value,
                                }))
                              : null
                            : setUserLoginDetails((uD) => ({
                                ...uD,
                                phone: "",
                              }))
                        }
                        maxLength={10}
                      />
                    </div>
                  </div>
                )}
                {type === "phone" ? (
                  <div className="otp-medium mb-medium">
                    <h3 className="otp-medium__heading">Receive OTP Via</h3>
                    <div className="otp-medium__blocks">
                      <div className="otp-medium__block">
                        <img
                          src={whatsapp}
                          alt=""
                          className="otp-medium__block-img"
                        />
                        <p className="otp-medium__block-name">
                          Whatsapp (Instant)
                        </p>
                        <input
                          type="checkbox"
                          className="checkbox-switch"
                          checked={smsType === "whatsapp"}
                          onChange={(e) =>
                            setSmsType(e.target.checked ? "whatsapp" : "sms")
                          }
                        />
                      </div>
                      <div className="otp-medium__block">
                        <img
                          src={sms}
                          alt=""
                          className="otp-medium__block-img"
                        />
                        <p className="otp-medium__block-name">
                          SMS (0 - 5 MIN)
                        </p>
                        <input
                          type="checkbox"
                          className="checkbox-switch"
                          checked={smsType === "sms"}
                          onChange={(e) =>
                            setSmsType(e.target.checked ? "sms" : "whatsapp")
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="auth__footer1 mb-medium">
                  <Link to="/login">Back to Login!</Link>
                </div>
                {message}
                <button
                  ref={submitBtnRef}
                  className={`${
                    activateLogin ? "enabled" : "disabled"
                  } button-primary`}
                  type="submit"
                >
                  Send password reset code
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;

import { useState, useEffect } from "react";
import { axiosInstance } from "../libries";

//for components
import Layout from "../containers/Layout";
import Breadcrumb from "../components/Breadcrumb";
import HelpCenter from "../components/HelpCenter";

const Faq = () => {
  const [isActive, setIsActive] = useState(null);
  const showIsActive = (id) => {
    setIsActive(id);
  };

  // state to track loading state
  const [loading, setLoading] = useState(true);

  // state to set active tabs
  const [activeHelpTabs, setActiveHelpTabs] = useState("faqs");

  const [faq, setFaq] = useState(null);

  useEffect(() => {
    axiosInstance()
      .get(`/get-faq-list`)
      .then((resp) => {
        setFaq(resp.data.message);
      })
      .catch((err) => {
        return err.message;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Layout>
      <Breadcrumb name="Product detail >" />
      <div className="container">
        <div className="help">
          <HelpCenter
            activeHelpTabs={activeHelpTabs}
            setActiveHelpTabs={setActiveHelpTabs}
          />
          <div className="faq">
            <h5 className="faq__text">Frequently Asked Question</h5>
            <div className="faq__body">
              <div className="faq__accordion">
                {loading && (
                  <div className="loading__spin__address">
                    <span className="loader"></span>
                  </div>
                )}
                {!loading &&
                  faq?.map((faqs, i) => (
                    <div key={i}>
                      {faqs.questions.length ? (
                        <h2 className="accordion__head">{faqs.topic_title}</h2>
                      ) : null}
                      {faqs.questions?.map((questn) => (
                        <div className="accordion__body" key={questn.id}>
                          <div className="accordion__title">
                            <h1 className="how__can">{questn.question}</h1>
                            <span className="open__close__faq">
                              {isActive === questn.id ? (
                                <ion-icon
                                  name="chevron-up-outline"
                                  onClick={() => setIsActive(null)}
                                ></ion-icon>
                              ) : (
                                <ion-icon
                                  name="chevron-down-outline"
                                  onClick={() => showIsActive(questn.id)}
                                ></ion-icon>
                              )}
                            </span>
                          </div>
                          {isActive === questn.id && (
                            <p className="text">{questn.answer}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default Faq;

import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import Specification from "../components/Specification";
import Details from "../components/Details";
import ProductPreview from "../components/productPreview";
import ProdPreviewLoader from "../components/loaders/ProdPreviewLoader";
import { SimilarProductLoader } from "../components/SimilarProduct";

// components
import { axiosInstance } from "../libries";
import Layout from "../containers/Layout";
import SimilarProduct from "../components/SimilarProduct";
import Breadcrumb from "../components/Breadcrumb";
import Explore from "../components/ExploreCategories";
import Ratings from "../components/Ratings";
import AllRatings from "../components/AllRatings";
import SignLogin from "../components/SignLogin";

// image
import { currency_formatter } from "../libries";
import { useDispatch, useSelector } from "react-redux";
import { addCartItem, removeCartItem } from "../store/slices/cartSlice";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const popularRef = useRef();
  const popularLeftRef = useRef();
  const popularRightRef = useRef();

  const myCurrentLocation = window.location.pathname;

  const [isDetails, setIsDetails] = useState(true);
  const [ratings, setRatings] = useState(false);
  const [isProd, setProd] = useState(false);
  const [similarProd, setSimilarProd] = useState(null);
  const [prodData, setProdData] = useState(null);
  const [popular, setPopular] = useState([]);
  const [is_loading, setIsLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.accessToken);
  const carts = useSelector((state) => state.cart.carts);
  const { id } = useParams();

  const [openRatings, setOpenRatings] = useState(false);

  const [closePopup, setClosePopup] = useState(false);

  const [prodDetailsBanners, setProdDetailsBanners] = useState(null);

  useEffect(() => {
    axiosInstance(userToken)
      .get(`/products/details/${id}`)
      .then((resp) => {
        const prod = resp.data.data.product;
        setProdData(prod);
      })
      .catch((err) => {});
  }, [id, dispatch, userToken]);

  useEffect(() => {
    setIsLoading(true);
    if (id)
      axiosInstance()
        .get(`/products/similar/${id}`)
        .then((resp) => setSimilarProd(resp.data.data.products))
        .catch((err) => {})
        .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    axiosInstance()
      .get(`/products/all/category/null?key=popular`)
      .then((resp) => setPopular(resp.data.data.products.data))
      .catch((_err) => {});
  }, []);

  const addCartNoVariation = (item) => {
    dispatch(
      addCartItem({
        id: item.id,
        p_slug: item.p_slug,
        src: item.images[0],
        name: item.name,
        brand: item.brand.name,
        color: item.color,
        price: Number(item.amount),
        description: item.description,
        quantity: 1,
        total: Number(item.amount) * 1,
        variation_info: null,
        stock_count: item.stock_count,
        weight: item.weight,
      })
    );

    if (userToken)
      axiosInstance(userToken)
        .post("/carts/add", {
          product_orders: [
            {
              product_id: item.id,
              quantity: 1,
            },
          ],
        })
        .then((_resp) => {})
        .catch((_err) => {});
  };

  const removeCart = (item, var_id) => {
    dispatch(removeCartItem({ ...item, variation_id: var_id }));

    if (userToken)
      axiosInstance(userToken)
        .post("/carts/remove", {
          product_id: item.id,
        })
        .then((_resp) => {})
        .catch((err) => {});
  };

  const openDetails = () => {
    setIsDetails(true);
    setRatings(false);
    setProd(false);
  };

  const openRating = () => {
    setRatings(true);
    setProd(false);
    setIsDetails(false);
  };

  const openProd = () => {
    setProd(true);
    setIsDetails(false);
    setRatings(false);
  };

  // useEffect controlling the left and right arrows of the popular accessories
  useEffect(() => {
    function triggerScrollEvent() {
      const scrollEvent = new Event("scroll", { bubbles: true });
      popularRef.current.dispatchEvent(scrollEvent);
    }
    popularLeftRef.current.addEventListener("click", function () {
      popularRef.current.scrollLeft -= 300; // Adjust the scroll amount as needed
      triggerScrollEvent();
    });
    popularRightRef.current.addEventListener("click", function () {
      popularRef.current.scrollLeft += 300; // Adjust the scroll amount as needed
      triggerScrollEvent();
    });
    popularRef.current.addEventListener("scroll", function () {
      const isScrollableToLeft = popularRef.current.scrollLeft > 0;
      const isScrollableToRight =
        popularRef.current.scrollLeft <
        popularRef.current.scrollWidth - popularRef.current.clientWidth;

      popularLeftRef.current.style.display = isScrollableToLeft
        ? "flex"
        : "none";
      popularRightRef.current.style.display = isScrollableToRight
        ? "flex"
        : "none";
    });
  }, []);

  // banners for product details page
  useEffect(() => {
    axiosInstance()
      .get(`/banner/product-details`)
      .then((resp) => {
        const res = resp.data.data.banners;
        setProdDetailsBanners(res);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <Layout>
        <Breadcrumb name="Product detail >" />
        <div className="container">
          <div className="prodPreview">
            <div className="prodPreview__wrap">
              <div className="prodPreview__main">
                {prodData && !is_loading ? (
                  <ProductPreview
                    data={prodData}
                    setClosePopup={setClosePopup}
                    closePopup={closePopup}
                    setProdData={setProdData}
                  />
                ) : (
                  <ProdPreviewLoader />
                )}
                <div className="details">
                  <div className="details__parts">
                    <div className="details__heading">
                      <div
                        className={`details__head ${
                          isDetails ? " active" : ""
                        }`}
                        onClick={() => openDetails()}
                      >
                        <h4>Key features</h4>
                      </div>
                      <div
                        className={`details__head ${isProd ? " active" : ""}`}
                        onClick={() => openProd()}
                      >
                        <h4>Product details</h4>
                      </div>
                      <div
                        className={`details__head ${ratings ? " active" : ""}`}
                        onClick={() => openRating()}
                      >
                        <h4>Ratings</h4>
                      </div>
                    </div>
                    {!prodData && <ProdPreviewLoader />}
                    {isDetails ? (
                      <Specification data={prodData} />
                    ) : ratings ? (
                      <Ratings
                        data={prodData.product_orders}
                        averageRating={prodData.average_rating}
                        setOpenRatings={setOpenRatings}
                      />
                    ) : (
                      <Details data={prodData?.description} />
                    )}
                  </div>
                </div>
              </div>
              <aside className="prodPreview__aside">
                <div className="question">
                  <div className="question__body">
                    <p className="question__text">
                      Question about this Product?
                    </p>
                    <div className="chat__btn">
                      <button className="chat__live" id="open_live_chat">
                        Live chat
                      </button>
                    </div>
                  </div>
                </div>

                {prodDetailsBanners?.side_top_banner?.img ? (
                  <div className="banner__below">
                    <a
                      href={`/categories?categories=${prodDetailsBanners?.side_top_banner?.category_id}`}
                    >
                      <img
                        src={prodDetailsBanners?.side_top_banner?.img}
                        alt="banner"
                        className="banner__imgs"
                      />
                    </a>
                  </div>
                ) : null}
                {prodDetailsBanners?.side_bottom_banner?.img ? (
                  <div className="banner__below">
                    <a
                      href={`/categories?categories=${prodDetailsBanners?.side_bottom_banner?.category_id}`}
                    >
                      <img
                        src={prodDetailsBanners?.side_bottom_banner?.img}
                        alt="banner"
                        className="banner__imgs"
                      />
                    </a>
                  </div>
                ) : null}
              </aside>
            </div>
          </div>
          {similarProd ? (
            <SimilarProduct title="Similar Products" data={similarProd} />
          ) : (
            <div className="similar">
              <h3 className="similar__name">Similar Products</h3>
              <div className="similar__product">
                <div className="similar__pictures">
                  {new Array(7).fill(null).map((_, i) => (
                    <SimilarProductLoader key={i} />
                  ))}
                </div>
              </div>
            </div>
          )}
          <Explore prodDetailsBanners={prodDetailsBanners} />
          <div className="popularAccess">
            <h4 className="accessories">
              <span className="accessories__txt">Popular Accessories</span>
              <span className="hr"></span>
            </h4>
            <div className="wireless">
              <button
                ref={popularLeftRef}
                className="btn__arrow__popular btn__arrow__popular--left"
                style={{ display: "none" }}
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                ref={popularRightRef}
                className="btn__arrow__popular btn__arrow__popular--right"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
              <div className="wireless__key" ref={popularRef}>
                {popular.map((itm, id) => (
                  <div className="wireless__part" key={id}>
                    <div className="wireless__imgWrap">
                      <Link to={`/product-details/${itm.p_slug}`}>
                        <img
                          src={itm.images[0]?.image_url}
                          alt="product"
                          className="wireless__img"
                        />
                      </Link>
                    </div>
                    <div className="wireless__board">
                      <p className="wireless__text">
                        {itm.name.length > 20
                          ? itm.name.slice(0, 20) + "..."
                          : itm.name}
                      </p>
                      <p className="wireless__price">
                        {currency_formatter(itm.amount)}
                      </p>
                      {carts.some((el) => el.id === itm.id) ? (
                        <button className="add" onClick={() => removeCart(itm)}>
                          Remove
                        </button>
                      ) : null}

                      {!carts.some((el) => el.id === itm.id) &&
                      !itm.is_fixed ? (
                        <Link to={`/product-details/${itm.p_slug}`}>
                          <button className="add">View product</button>
                        </Link>
                      ) : null}

                      {!carts.some((el) => el.id === itm.id) && itm.is_fixed ? (
                        <button
                          className="add"
                          onClick={() => addCartNoVariation(itm)}
                        >
                          Add To Cart
                        </button>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>

      {openRatings && (
        <AllRatings
          setOpenRatings={setOpenRatings}
          data={prodData.product_orders}
        />
      )}
      {closePopup && (
        <SignLogin
          setClosePopup={setClosePopup}
          closePopup={closePopup}
          myCurrentLocation={myCurrentLocation}
        />
      )}
    </>
  );
};

export default ProductDetails;

import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allsaved } from "../store/slices/savedItemSlice";
import { addCartItem, clearCarts } from "../store/slices/cartSlice";
import validator from "validator";

import axios from "axios";

import { login } from "../store/slices/authSlice";
import { baseUrl } from "../libries";

// images
import logo from "../assets/new-imgs/shopnig-logoo.svg";
import authphoto from "../assets/new-imgs/auth-banner.svg";
import { isNumber } from "../utils/func";
import useAlert from "../hooks/useAlert";
import authbg from "../assets/new-imgs/auth-dark.svg";
import logodark from "../assets/new-imgs/shopnig-darklogo.svg";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitBtnRef = useRef();
  const uiMode = useSelector((state) => state.app.uiMode);
  const carts = useSelector((state) => state.cart.carts);

  // state to get login inputs
  const [userLoginDetails, setUserLoginDetails] = useState({
    phone_number: "",
    email: "",
    password: "",
  });

  // Type
  const [type, ] = useState("email");

  // state to show password or hide password
  const [showPassword, setShowPassword] = useState(false);

  const [message, setMessage, clearMessage] = useAlert();

  const handleLoginInputs = (e) => {
    const { name, value } = e.target;
    setUserLoginDetails({
      ...userLoginDetails,
      [name]: value,
    });
  };

  const loginUser = (e) => {
    e.preventDefault();

    if (!activateLogin) return setMessage("warning", "Fill in all fields");

    if (type === "email" && !validator.isEmail(userLoginDetails.email))
      return setMessage("warning", "Enter a valid email address");

    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Logging in`;
    submitBtnRef.current.setAttribute("disabled", "disabled");

    let token;

    axios({
      url: `${baseUrl}/login`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        mode: type,
        [type]: `${type === "phone_number" ? "+234" : ""}${
          userLoginDetails[type]
        }`,
        password: userLoginDetails.password,
      }),
    })
      .then((response) => {
        token = response.data.data.token.accessToken;

        // making a call to fetch user account
        return axios({
          url: `${baseUrl}/accounts`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      })
      .then(async (resp) => {
        const userProfile = resp.data.data.user;
        userProfile.verification = resp.data.data.verification;
        userProfile.wallet = resp.data.data.wallet;
        userProfile.order_count = resp.data.data.order_count;
        userProfile.bank = resp.data.data.bank;

        dispatch(
          login({
            accessToken: token,
            userDetails: userProfile,
          })
        );

        //first sending items in the cart to the backend and then refetching cart items
        if (carts.length) {
          let allVariation =
            carts?.map((cartItem) => {
              return cartItem.variation_info !== null
                ? {
                    product_id: cartItem.id,
                    variation: cartItem.variation_info?.map((variatn) => ({
                      id: variatn.id,
                      quantity: variatn.quantity,
                    })),
                  }
                : {
                    product_id: cartItem.id,
                    quantity: cartItem.quantity,
                  };
            }) || [];

          const resp_1 = await axios({
            url: `${baseUrl}/carts/add`,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify({ product_orders: allVariation }),
          });
          const cartResp = resp_1.data.message;

        }

        const resp_2 = await axios({
          url: `${baseUrl}/carts`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const allCarts = resp_2.data.data.cart;
        if (!allCarts) {
          dispatch(clearCarts());
        }
        if (allCarts) {
          allCarts.map((cart) => {
            let allVariation = [];

            if (cart.variation !== null) {
              cart.variation.forEach((cartVar) => {
                allVariation.push({
                  amount: cartVar.amount,
                  id: cartVar.combo_id,
                  quantity: cartVar.quantity,
                  total: Number(cartVar.amount * cartVar.quantity),
                  stock_count: cartVar.in_stock,
                  variations: cartVar.variations,
                });
              });
            }
            return dispatch(
              addCartItem({
                id: cart.product.id,
                p_slug: cart.product.p_slug,
                name: cart.product.name,
                color: cart.product.color,
                quantity: cart.quantity,
                src: cart.images,
                brand: "",
                price: Number(cart.product.amount),
                total: Number(cart.product.amount) * cart.quantity,
                variation_info: cart.product.is_fixed ? null : allVariation,
                stock_count:
                  cart.product.is_fixed === 1
                    ? cart.product.stock_count
                    : cart.product.in_stock,
                weight: cart.product.weight,
              })
            );
          });
        }

        // fetch all saved items from  server and save on local storage
        const resp_3 = await axios({
          url: `${baseUrl}/saved-products/saved-items`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        let saves = resp_3.data.data.saved_products;
        const saved = saves.map((el) => ({
          ...el.product,
          discount: el.product.percentage_discount,
          brand: "samsung",
          images: el.images,
        }));
        dispatch(allsaved(saved));

        navigate("/");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        submitBtnRef.current.removeAttribute("disabled", "disabled");
        submitBtnRef.current.innerHTML = "Sign In";
      });
  };

  useEffect(() => {
    clearMessage();
  }, [
    type,
    userLoginDetails.email,
    userLoginDetails.phone_number,
    userLoginDetails.password,
    clearMessage,
  ]);

  const activateLogin = userLoginDetails[type] && userLoginDetails.password;

  return (
    <>
      {/* <ModeToggle /> */}
      <div className="auth__container">
        <div className="auth__photo">
          <img src={uiMode === "light" ? authphoto : authbg} alt="login" />
        </div>
        <form onSubmit={loginUser} className="form__auth">
          <div className="auth__main">
            <div className="auth__img-block">
              <a href="/">
                <img
                  src={uiMode === "light" ? logo : logodark}
                  alt=""
                  className={["auth__img", "cursor-pointer"].join(" ")}
                />
              </a>
            </div>
            <div className="auth__card">
              <div className="auth__card-main">
                <div className="auth__header">
                  <h3 className="auth__heading">Log in to your account</h3>
                </div>
                {/* <div className="auth__btns">
                  <button
                    type="button"
                    autoComplete="off"
                    className={type === "email" ? "active" : ""}
                    onClick={() => setType("email")}
                  >
                    Email
                  </button>
                  <button
                    type="button"
                    autoComplete="off"
                    className={type === "phone_number" ? "active" : ""}
                    onClick={() => setType("phone_number")}
                  >
                    Phone
                  </button>
                </div> */}
                <div className="auth__form">
                  {type === "email" ? (
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        name="email"
                        value={userLoginDetails.email}
                        type="email"
                        placeholder="Enter email address"
                        autoComplete="off"
                        className="form-input form-input--1"
                        onChange={handleLoginInputs}
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label className="form-label">Phone Number</label>
                      <div className="form-input-group form-input-group--1">
                        <button type="button">+234</button>
                        <input
                          name="phone_number"
                          value={userLoginDetails.phone_number}
                          type="text"
                          placeholder="Enter your phone number"
                          autoComplete="off"
                          onChange={(e) =>
                            e.target.value
                              ? isNumber(e.target.value)
                                ? setUserLoginDetails((uD) => ({
                                    ...uD,
                                    phone_number: +e.target.value,
                                  }))
                                : null
                              : setUserLoginDetails((uD) => ({
                                  ...uD,
                                  phone_number: "",
                                }))
                          }
                          maxLength={10}
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label className="form-label">Password</label>
                    <div className="input-group input-group--1 flex items-center p-2 cursor-pointer">
                      <input
                        placeholder="Enter your password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        autoComplete="off"
                        value={userLoginDetails.password}
                        onChange={handleLoginInputs}
                      />
                      <button
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        type="button"
                      >
                        {showPassword ? (
                          <ion-icon name="eye-outline"></ion-icon>
                        ) : (
                          <ion-icon name="eye-off-outline"></ion-icon>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="auth__footer1 mb-medium">
                    <Link to="/forgot-password">Forgot your password?</Link>
                  </div>
                  {message}
                  <button
                    ref={submitBtnRef}
                    className={`${
                      activateLogin ? "enabled" : "disabled"
                    } button-primary`}
                    type="submit"
                  >
                    Sign In
                  </button>
                  <div className="auth__footer">
                    Don't have an account?{" "}
                    <Link to="/sign-up">Create an account</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;

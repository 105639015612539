import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { axiosInstance, currency_formatter } from "../libries";
import shopnigLogo from "../assets/new-imgs/shopnig-logoo.svg";
import WithAuth from "../WithAuth";
import Notifications from "../pages/Notifications";
import shopnigLogoDark from "../assets/new-imgs/shopnig-darklogo.svg";

import { changeMode } from "../store/slices/appSlice";

import {
  remove_coupon_amount_percentage,
  remove_new_total_products,
  resetShippingFee,
  reset_coupon,
  resetTotalOrderPrice,
  resetTotalProduct,
} from "../store/slices/cartSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.userDetails);
  const { carts } = useSelector((state) => state.cart);
  const [toggle, setToggle] = useState(false);
  const [categories, setCategories] = useState([]);
  const [accountToggle, setAccountToggle] = useState(false);
  const [helpToggle, setHelpToggle] = useState(false);
  const [search_suggestions, setSearchSuggestions] = useState(null);
  const [value, setValue] = useState("");
  const [searchParams] = useSearchParams();

  // getting the popular categories here to display on the navbar
  const { popularCategories } = useSelector((state) => state.cache);

  const uiMode = useSelector((state) => state.app.uiMode);

  const [showNotifications, setShowNotifications] = useState(false);

  let total = 0;
  carts?.length &&
    carts.forEach((itm) => {
      if (!itm.variation_info?.length) total += 1;
      else total += itm.variation_info.length;
    });

  useEffect(() => {
    if (!searchParams.get("prod")) {
      setValue("");
    }
  }, [searchParams]);

  useEffect(() => {
    axiosInstance()
      .get(`/categories/nav-sub-categories`)
      .then((resp) => setCategories(resp.data.data.categories_sub))
      .catch((err) => {});
  }, []);

  // search endpoint for navbar
  useEffect(() => {
    if (!value) return;
    const controller = new AbortController(); // Create an AbortController
    let activeRequest = true; // A flag to check if the request is the most recent

    axiosInstance()
      .get(`/search-and-filter-products?search_term=${value}`, {
        signal: controller.signal, // Pass the signal to axios to cancel the request
      })
      .then((resp) => {
        if (activeRequest) {
          // Only update if this is the most recent request
          setSearchSuggestions(resp.data.data.products.data);
        }
      })
      .catch((_err) => {
        // if (axios.isCancel(err)) {
        //   console.log("Request canceled:", err.message);
        // } else {
        //   console.error("Error:", err);
        // }
      });

    // Cleanup function to abort the previous request and prevent setting state from stale responses
    return () => {
      controller.abort(); // Abort previous request
      activeRequest = false; // Invalidate previous requests
    };
  }, [value]);

  const handleEditCart = () => {
    dispatch(resetShippingFee());
    dispatch(reset_coupon());
    dispatch(resetTotalOrderPrice());
    dispatch(resetTotalProduct());
    dispatch(remove_coupon_amount_percentage());
    dispatch(remove_new_total_products());
    navigate("/cart");
  };

  const logOut = () => {
    localStorage.removeItem("app_state");
    window.location.assign("/login");
  };

  // resetting brand and filter option when a popular category is selected from the navbar
  const handlePopularCategorySel = () => {
    searchParams.delete("filter");
  };

  return (
    <>
      {showNotifications ? (
        <Notifications closeHandler={() => setShowNotifications(false)} />
      ) : null}
      <div className="header-container">
        <header className={`header ${toggle ? "header--sidebar-open" : ""}`}>
          <div className="container header__container">
            <button
              className="header__sidebar-toggle"
              onClick={() => setToggle((t) => !t)}
              aria-label="toggle sidebar menu"
            >
              <ion-icon name="menu-outline"></ion-icon>
              <ion-icon name="close-outline"></ion-icon>
            </button>
            <Link to="/" aria-label="shopnig logo">
              <img
                src={uiMode === "light" ? shopnigLogo : shopnigLogoDark}
                alt="logo"
                className="header__logo"
              />
            </Link>
            <form
              className="header__search"
              action="/categories"
              method="get"
              onSubmit={(e) => {
                e.preventDefault();
                navigate(`/categories?prod=${value}`);
              }}
            >
              <div className="header__search-main">
                <ion-icon
                  name="search-outline"
                  className="header__search-icon"
                ></ion-icon>
                <input
                  type="text"
                  name="prod"
                  value={value}
                  placeholder="Search for products"
                  className="header__search-input"
                  autoComplete="off"
                  onChange={(event) => {
                    setValue(event.target.value);
                  }}
                />
                <div className="suggestions">
                  <ul>
                    {value &&
                      search_suggestions?.map(({ id, name, p_slug }) => (
                        <li
                          key={id}
                          onClick={(e) => {
                            setValue(e.target.innerText);

                            document
                              .querySelector(
                                ".header__search-main .suggestions"
                              )
                              .classList.add("hidden");

                            navigate(`/product-details/${p_slug}`);
                          }}
                        >
                          {name}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <button
                type="submit"
                className={`${
                  value === "" ? "disable__search__nav" : "enable__search__nav"
                } header__search-btn`}
                onClick={() => navigate(`/categories?prod=${value}`)}
              >
                Search
              </button>
            </form>
            <div className="header__icon-links">
              <Link to="/dashboard" className="header__icon-link">
                <ion-icon name="person-outline"></ion-icon>
              </Link>
              <Link to="/cart" className="header__icon-link" aria-label="cart">
                <ion-icon name="cart-outline"></ion-icon>
                {total ? <sup>{total}</sup> : ""}
              </Link>
              {user ? (
                <span
                  className="header__icon-link"
                  onClick={() => setShowNotifications(true)}
                >
                  <ion-icon name="notifications-outline"></ion-icon>
                  {user.unread_notification_count ? (
                    <sup>{user.unread_notification_count}</sup>
                  ) : null}
                </span>
              ) : null}

              <label
                className="toggle"
                htmlFor="toggle-theme"
                arial-label="Toggle dark mode"
                onChange={(e) =>
                  dispatch(changeMode(e.target.checked ? "dark" : "light"))
                }
              >
                <input
                  id="toggle-theme"
                  type="checkbox"
                  checked={uiMode === "dark"}
                  onChange={() => {}}
                />
                <span>
                  <ion-icon
                    name="sunny"
                    style={{ color: "#0F241A" }}
                  ></ion-icon>
                </span>
                <span>
                  <ion-icon name="moon" style={{ color: "#fff" }}></ion-icon>
                </span>
              </label>
            </div>
            <ul className="header__navbar">
              <li className="header__navbar-item header__navbar-item--has-sub">
                <div className="header__navbar-nolink">
                  <ion-icon name="person-outline"></ion-icon>
                  <span>{user ? `Hi, ${user.first_name}` : "My Account"}</span>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </div>
                <div className="header__navbar-sub">
                  <div className="header__navbar-sub-menu">
                    <Link to="/dashboard" className="header__navbar-sub-link">
                      My Wallet
                    </Link>
                    <Link
                      to="/dashboard/my-account"
                      className="header__navbar-sub-link"
                    >
                      My Account
                    </Link>
                    <Link
                      to="/dashboard/orders"
                      className="header__navbar-sub-link"
                    >
                      Orders
                    </Link>
                    <Link
                      to="/dashboard/delivery_addresses"
                      className="header__navbar-sub-link"
                    >
                      Delivery Addresses
                    </Link>
                    <Link
                      to="/dashboard/saved-items"
                      className="header__navbar-sub-link"
                    >
                      Saved Items
                    </Link>
                    <Link
                      to="/dashboard/reviews"
                      className="header__navbar-sub-link"
                    >
                      Reviews
                    </Link>
                    <Link
                      to="/dashboard/recently-viewed"
                      className="header__navbar-sub-link"
                    >
                      Recently viewed
                    </Link>
                    <div className="header__navbar-sub-divider"></div>
                    <div className="header__navbar-sub-btn-block">
                      <button
                        className={!user ? "active" : ""}
                        onClick={() => {
                          if (!user) navigate("/login");
                          if (user) logOut();
                        }}
                      >
                        {user ? "Log Out" : "Log In"}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li className="header__navbar-item header__navbar-item--has-sub">
                <div className="header__navbar-nolink">
                  <ion-icon name="help-circle-outline"></ion-icon>
                  <span>Help</span>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </div>
                <div className="header__navbar-sub">
                  <div className="header__navbar-sub-menu">
                    <Link to="/faq" className="header__navbar-sub-link">
                      FAQ
                    </Link>
                    <Link to="/about-us" className="header__navbar-sub-link">
                      About Us
                    </Link>
                    <Link
                      className="header__navbar-sub-link"
                      id="open_live_chat"
                    >
                      Contact Us
                    </Link>
                    <Link
                      to="/refund-policy"
                      className="header__navbar-sub-link"
                    >
                      Refund Policy
                    </Link>
                    <Link
                      to="/terms-condition"
                      className="header__navbar-sub-link"
                    >
                      Terms & Conditions
                    </Link>
                    <Link
                      to="/privacy-policy"
                      className="header__navbar-sub-link"
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </li>
              <li className="header__navbar-item" onClick={handleEditCart}>
                <Link to="/cart" className="header__navbar-link">
                  <ion-icon name="cart-outline"></ion-icon>
                  <span>Cart</span>
                  <sup style={!total ? { visibility: "hidden" } : {}}>
                    {total}
                  </sup>
                </Link>
              </li>
            </ul>
            <div className="header__right">
              {!user ? (
                <>
                  <Link to="/login" className="header__navbar-link">
                    <ion-icon name="log-in-outline"></ion-icon>
                    <span>Login</span>
                  </Link>
                  <div className="header__right-divider"></div>
                  <Link to="/sign-up" className="header__navbar-link">
                    <ion-icon name="log-out-outline"></ion-icon>
                    <span>Register</span>
                  </Link>
                </>
              ) : (
                <Link to="/dashboard" className="header__navbar-link">
                  <ion-icon name="wallet-outline"></ion-icon>
                  <span>
                    {currency_formatter(user.wallet.available_balance ?? 0)}
                  </span>
                </Link>
              )}
            </div>
          </div>
          <aside className="sidebar1 header__sidebar">
            <div className="sidebar1__main">
              <div className="sidebar1__block">
                <div className="sidebar1__flex">
                  {!user ? (
                    <>
                      <Link to="/login" className="header__navbar-link">
                        <ion-icon name="log-in-outline"></ion-icon>
                        <span>Login</span>
                      </Link>
                      <div className="header__right-divider"></div>
                      <Link to="/sign-up" className="header__navbar-link">
                        <ion-icon name="log-out-outline"></ion-icon>
                        <span>Register</span>
                      </Link>
                    </>
                  ) : (
                    <Link to="/dashboard" className="header__navbar-link">
                      <ion-icon name="wallet-outline"></ion-icon>
                      <span>
                        {currency_formatter(user.wallet.available_balance ?? 0)}
                      </span>
                    </Link>
                  )}
                </div>
              </div>
              <div className="sidebar1__block">
                <div
                  className="sidebar1__menu-header mb-0"
                  onClick={() => setAccountToggle((at) => !at)}
                >
                  <p>{user ? `Hi, ${user.first_name}` : "My Account"}</p>
                  <ion-icon
                    name={
                      accountToggle
                        ? "chevron-up-outline"
                        : "chevron-down-outline"
                    }
                  ></ion-icon>
                </div>
                {accountToggle ? (
                  <ul className="sidebar1__menu">
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/dashboard"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        My Wallet
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/dashboard/my-account"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        My Account
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/dashboard/orders"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Orders
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/dashboard/delivery_addresses"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Delivery Addresses
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/dashboard/saved-items"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Saved Items
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/dashboard/reviews"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Reviews
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/dashboard/recently-viewed"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Recently Viewed
                      </Link>
                    </li>
                  </ul>
                ) : null}
              </div>
              <div className="sidebar1__block">
                <div className="sidebar1__menu-header mb-0">
                  <p>Categories</p>
                </div>
                <ul className="sidebar1__menu">
                  <li className="sidebar1__menu-item">
                    <Link
                      to="/categories"
                      className="sidebar1__menu-link"
                      onClick={() => setToggle((t) => !t)}
                    >
                      <ion-icon name="menu-outline"></ion-icon>
                      All Categories
                    </Link>
                  </li>
                  {categories.map((itm) => (
                    <div key={itm.id}>
                      {itm.is_visible === 1 ? (
                        <li className="sidebar1__menu-item">
                          <Link
                            to={`/categories?categories=${itm.id}`}
                            className="sidebar1__menu-link"
                            onClick={() => setToggle((t) => !t)}
                          >
                            <img src={itm.icon_url} alt={itm.name} />
                            {itm.name}
                          </Link>
                        </li>
                      ) : null}
                    </div>
                  ))}
                </ul>
              </div>
              <div className="sidebar1__block">
                <div
                  className="sidebar1__menu-header mb-0"
                  onClick={() => setHelpToggle((ht) => !ht)}
                >
                  <p>Help</p>
                  <ion-icon
                    name={
                      helpToggle ? "chevron-up-outline" : "chevron-down-outline"
                    }
                  ></ion-icon>
                </div>
                {helpToggle ? (
                  <ul className="sidebar1__menu">
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/faq"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        FAQ
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/about-us"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        id="open_live_chat"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/refund-policy"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Refund Policy
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/terms-condition"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                    <li className="sidebar1__menu-item">
                      <Link
                        to="/privacy-policy"
                        className="sidebar1__menu-link"
                        onClick={() => setToggle((t) => !t)}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                ) : null}
              </div>
              <div className="sidebar1__block">
                <div className="header__navbar-sub-btn-block">
                  <button
                    className={!user ? "active" : ""}
                    onClick={() => {
                      if (!user) navigate("/login");
                      if (user) logOut();
                      setToggle((t) => !t);
                    }}
                  >
                    {user ? "Log Out" : "Log In"}
                  </button>
                </div>
              </div>
            </div>
          </aside>
          <div className="sidebar-overlay"></div>
        </header>

        <section className="top-bar">
          <div className="container top-bar__container">
            <div className="top-bar__categories">
              <div className="top-bar__categories-block">
                <ion-icon name="menu-outline"></ion-icon>
                All Categories
              </div>
              <div className="top-bar__menu">
                <div className="top-bar__menu-header"></div>
                <div className="top-bar__menu-main">
                  <div className="top-bar__menu-left">
                    <ul className="top-bar__menu1">
                      {categories?.map((itm) => (
                        <div key={itm.id}>
                          {itm.is_visible === 1 ? (
                            <li className="top-bar__menu1-item">
                              <Link
                                to={`/categories?categories=${itm.id}`}
                                className="top-bar__menu1-link"
                              >
                                <img
                                  src={itm.icon_url}
                                  alt={itm.name}
                                  className="iconImage"
                                />
                                {itm.name}
                              </Link>
                              <div className="top-bar__menu-right">
                                <div className="top-bar__menu2-links">
                                  {itm.sub_category.length > 0 && (
                                    <h3 className="top-bar__menu-heading">
                                      Sub-categories
                                    </h3>
                                  )}
                                  <div className="split__nav">
                                    {itm.sub_category.map((sub, i) => (
                                      <div key={i}>
                                        {sub.is_visible === 1 ? (
                                          <Link
                                            to={`/categories?categories=${itm.id}`}
                                            className="top-bar__menu2-link"
                                          >
                                            {sub.name}
                                          </Link>
                                        ) : null}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ) : null}
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ul className="top-bar__navbar top-bar__navbar-main">
              {popularCategories?.map((cat) => (
                <li
                  className="top-bar__navbar-item"
                  key={cat.id}
                  onClick={handlePopularCategorySel}
                >
                  <Link
                    to={`/categories?categories=${cat.category_id}`}
                    className="top-bar__navbar-link"
                  >
                    <img
                      src={cat.icon_url}
                      alt={cat.name}
                      className="iconImage"
                    />
                    {cat.name}
                  </Link>
                </li>
              ))}
            </ul>

            <ul className="top-bar__navbar-right">
              <label
                className="toggle"
                arial-label="Toggle dark mode"
                onChange={(e) =>
                  dispatch(changeMode(e.target.checked ? "dark" : "light"))
                }
              >
                <input
                  type="checkbox"
                  checked={uiMode === "dark"}
                  onChange={() => {}}
                />
                <span>
                  <ion-icon
                    name="sunny"
                    style={{ color: "#0F241A" }}
                  ></ion-icon>
                </span>
                <span>
                  <ion-icon name="moon" style={{ color: "#fff" }}></ion-icon>
                </span>
              </label>
              {user ? (
                <li className="top-bar__navbar-item">
                  <span
                    className="top-bar__navbar-link notify-count"
                    onClick={() => setShowNotifications(true)}
                  >
                    <ion-icon name="notifications-outline"></ion-icon>
                    {user.unread_notification_count ? (
                      <sup>{user.unread_notification_count}</sup>
                    ) : null}
                  </span>
                </li>
              ) : null}
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default WithAuth(Navbar);
